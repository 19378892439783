<template>
  <div class="goodsList container" :key="key">
    <el-tabs v-model="type" @tab-click="typeChange">
      <el-tab-pane label="出售中" name="1" />
      <el-tab-pane label="未上架" name="3" />
      <el-tab-pane label="回收站" name="4" />
    </el-tabs>
    <div class="searchbox">
      <el-form inline>
        <el-form-item label="商品名称">
          <el-input
            v-model="goods_name"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input
            v-model="goods_code"
            placeholder="请输入商品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-cascader
            v-model="category"
            :options="categoryList"
            :props="{ expandTrigger: 'hover', checkStrictly: true }"
            placeholder="选择后台分类"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="下架来源" v-if="type == 3">
          <el-select
            v-model="off_source"
            placeholder="选择下架来源"
            style="width: 150px"
          >
            <el-option label="自主下架" value="1"> </el-option>
            <el-option label="未上架暂存仓库" value="5"> </el-option>
            <el-option label="自动下架" value="6"> </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form>
    </div>
    <div class="searchbox">
      <el-button
        type="primary"
        v-show="type == 1 || type == 3"
        @click="toAddGood"
        v-if="module_id_array.includes('48') || module_id_array.includes('999')"
        >发布商品</el-button
      >
      <el-button
        v-show="type == 1"
        @click="offGoods(null)"
        v-if="module_id_array.includes('43') || module_id_array.includes('999')"
        >下架商品</el-button
      >
      <el-button
        v-show="type == 3"
        @click="deleteGoodsSort(null)"
        v-if="module_id_array.includes('45') || module_id_array.includes('999')"
        >删除商品</el-button
      >
      <el-button
        v-show="type == 4"
        type="primary"
        @click="recoveryDeleteGoods(null)"
        v-if="module_id_array.includes('47') || module_id_array.includes('999')"
        >恢复商品</el-button
      >
    </div>
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="排序" sortable prop="sort" width="120">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.sort"
              step-strictly
              :step="1"
              :min="0"
              :max="999999999"
              :controls="false"
              style="width: 80px"
              @change="setSort(scope.row.goods_id, scope.row.sort)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="商品" width="300">
          <template slot-scope="scope">
            <div class="flex align-center">
              <div class="imgbox">
                <el-image
                  :src="scope.row.picture_str"
                  alt
                  class="goodsImg"
                  fit="contain"
                  lazy
                />
              </div>
              <p>{{ scope.row.goods_name }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="create_time"
          min-width="100"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="是否启用规格"
          min-width="100"
          prop="create_time"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.open_spec == 1">启用</span>
            <span v-show="scope.row.open_spec == 0">没启用</span>
          </template>
        </el-table-column>
        <el-table-column label="商品编码">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.goods_code" :key="index">
              {{ item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="real_sales"
          label="实际销量"
          min-width="100"
          v-if="type == 1"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="stock" label="总库存" sortable>
          <template slot-scope="scope">
            <el-button type="text" @click="checkStock(scope.row)">
              {{ scope.row.stock }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="下架来源"
          v-if="type == 3 || type == 4"
          prop="off_shelf_reason"
        ></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="offGoods(scope.row.goods_id)"
              v-if="
                scope.row.state == 2 &&
                  (module_id_array.includes('43') ||
                    module_id_array.includes('999'))
              "
              >下架</el-button
            >
            <el-button
              type="text"
              @click="showStock(scope.row)"
              v-if="
                scope.row.state == 2 &&
                  (module_id_array.includes('52') ||
                    module_id_array.includes('999'))
              "
              >修改库存</el-button
            >
            <el-button
              type="text"
              @click="onGoods(scope.row.goods_id)"
              v-if="
                scope.row.state != 2 &&
                  scope.row.is_delete == 0 &&
                  (module_id_array.includes('46') ||
                    module_id_array.includes('999'))
              "
              >上架</el-button
            >
            <el-button
              type="text"
              @click="toEditGoods(scope.row.goods_id)"
              v-if="
                scope.row.state != 2 &&
                  scope.row.is_delete == 0 &&
                  (module_id_array.includes('49') ||
                    module_id_array.includes('999'))
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="deleteGoodsSort(scope.row.goods_id)"
              v-if="
                scope.row.state != 2 &&
                  scope.row.is_delete == 0 &&
                  (module_id_array.includes('45') ||
                    module_id_array.includes('999'))
              "
              >删除</el-button
            >
            <el-button
              type="text"
              @click="recoveryDeleteGoods(scope.row.goods_id)"
              v-if="
                scope.row.state != 2 &&
                  scope.row.is_delete == 1 &&
                  (module_id_array.includes('47') ||
                    module_id_array.includes('999'))
              "
              >恢复</el-button
            >
            <el-button type="text" @click="checkGoods(scope.row.goods_id)"
              >查看</el-button
            >
            <el-button type="text" @click="copyGoods(scope.row.goods_id)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageNumber"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin: 30px 0 40px 0"
    ></el-pagination>

    <!-- 修改库存 -->
    <el-dialog
      title="修改库存"
      :close-on-click-modal="false"
      :visible.sync="stockShow"
      width="400px"
    >
      <div>
        <el-form
          :model="info"
          label-width="60px"
          :rules="rules"
          ref="stockForm"
        >
          <el-form-item label="数量" prop="stock" v-if="info.open_spec == 0">
            <el-input-number
              v-model="info.stock"
              step-strictly
              :step="1"
              :min="0"
              :max="999999999"
              :controls="false"
              placeholder="填写库存"
            ></el-input-number>
          </el-form-item>
          <template v-if="info.open_spec == 1">
            <div
              class="flex"
              v-for="(item, index) in info.list"
              :key="item.sku_id"
            >
              <el-form-item label-width="0" label="" style="width:120px">{{
                item.sku_name
              }}</el-form-item>
              <el-form-item
                label="数量"
                :prop="'list.' + index + '.stock'"
                :rules="rules.stock"
              >
                <el-input-number
                  v-model="item.stock"
                  step-strictly
                  :step="1"
                  :min="0"
                  :max="999999999"
                  :controls="false"
                  placeholder="填写库存"
                ></el-input-number>
              </el-form-item>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stockShow = false">取 消</el-button>
        <el-button type="primary" @click="stockConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 仅查看库存 -->
    <el-dialog
      title="查看库存"
      :visible.sync="stockShowCheck"
      :close-on-click-modal="false"
      width="400px"
    >
      <div>
        <el-form :model="info" label-width="60px" ref="stockForm">
          <el-form-item label="数量" prop="stock" v-if="info.open_spec == 0">
            <span>{{ info.stock }}</span>
          </el-form-item>
          <template v-if="info.open_spec == 1">
            <div
              class="flex"
              v-for="(item, index) in info.list"
              :key="item.sku_id"
            >
              <el-form-item label-width="0" label="" style="width:120px">{{
                item.sku_name
              }}</el-form-item>
              <el-form-item
                label="数量"
                :prop="'list.' + index + '.stock'"
                :rules="rules.stock"
              >
                <span>{{ item.stock }}</span>
              </el-form-item>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="stockShowCheck = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>

    <!-- 下架弹框 -->
    <el-dialog title="提示消息" :visible.sync="confirmdialog" width="400px">
      <div>
        {{ newmassage }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmdialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmBtn(comkey)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonUtil from "../../utils/commonUtil.js";
export default {
  name: "goodsList",
  data() {
    return {
      type: "1",
      goods_name: "",
      goods_code: "",
      off_source: "",
      categoryList: [],
      category: [],
      tableData: [],
      order_sort: 2,
      total: 0,
      page: 1,
      pageNumber: 10,
      multipleSelection: [],
      stockShow: false,
      stockShowCheck: false,
      confirmdialog: false,
      dia_goods_id: "",
      newmassage: "",
      comkey: "",
      rules: {
        stock: [
          {
            required: true,
            message: "请输入商品库存",
            trigger: "blur",
          },
        ],
      },
      info: {},
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      key: 1,
    };
  },
  components: {},
  mounted() {
    this.getCategoryList();
  },

  activated() {
    this.getList();
  },
  methods: {
    getCategoryList() {
      this.axios.post("/store/Shopgoods/getCategoryList").then((res) => {
        let list = res.data.list;
        let arr = [];
        list.forEach((ele) => {
          if (ele.child.length == 0) {
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
            });
          } else {
            let childArr = [];
            ele.child.forEach((e) => {
              childArr.push({
                value: e.category_id,
                label: e.category_name,
              });
            });
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
              children: childArr,
            });
          }
        });
        this.categoryList = arr;
      });
    },
    getList() {
      this.tableData = [];
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
        goods_name: this.goods_name,
        off_source: this.off_source,
        type: this.type,
        goods_code: this.goods_code,
        order_sort: this.order_sort,
      };
      if (this.category[0]) {
        data.category_id_1 = this.category[0];
      }
      if (this.category[1]) {
        data.category_id_2 = this.category[1];
      }
      this.axios.post("/store/Shopgoods/getGoodsList", data).then((res) => {
        this.total = res.data.total;
        let list = res.data.list;
        list.forEach((ele) => {
          ele.create_time = this.dayjs
            .unix(ele.create_time)
            .format("YYYY-MM-DD HH:mm");
        });
        this.tableData = list;
        this.key++;
      });
    },
    typeChange() {
      this.page = 1;
      this.getList();
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    toAddGood() {
      this.$router.push("/goods/AddGood");
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    //修改排序
    setSort(goods_id, sort) {
      let data = {
        goods_id,
        sort,
      };
      this.axios
        .post("/store/Shopgoods/updateGoodsSort", data)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList();
        })
        .catch((err) => {
          console.log(err);
          this.getList();
        });
    },
    reset() {
      this.goods_name = "";
      this.goods_code = "";
      this.category = [];
      this.order_sort = 2;
      this.page = 1;
      this.off_source = "";
      this.getList();
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    search(event) {
      this.page = 1;
      this.getList();
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    confirmBtn(val) {
      switch (val) {
        case "takeoff":
          let arr = this.multipleSelection.map((ele) => {
            return ele.goods_id;
          });
          let data = {
            goods_id: this.dia_goods_id || arr.toString(),
          };
          this.axios.post("/store/Shopgoods/offGoods", data).then((res) => {
            this.getList();
            this.$message.success("下架成功");
          });
          this.confirmdialog = false;
          break;
        case "puton":
          const arr1 = this.multipleSelection.map((ele) => {
            return ele.goods_id;
          });
          const data1 = {
            goods_id: this.dia_goods_id || arr1.toString(),
          };
          this.axios.post("/store/Shopgoods/onGoods", data1).then((res) => {
            this.getList();
            this.$message.success("上架成功");
          });
          this.confirmdialog = false;
          break;
        case "delete":
          const arr2 = this.multipleSelection.map((ele) => {
            return ele.goods_id;
          });
          const data2 = {
            goods_id: this.dia_goods_id || arr2.toString(),
          };
          this.axios
            .post("/store/Shopgoods/deleteGoodsSort", data2)
            .then((res) => {
              this.getList();
              this.$message.success("删除成功");
            });
          this.confirmdialog = false;
          break;
        case "recover":
          const arr3 = this.multipleSelection.map((ele) => {
            return ele.goods_id;
          });
          const data3 = {
            goods_id: this.dia_goods_id || arr3.toString(),
          };
          this.axios
            .post("/store/Shopgoods/recoveryDeleteGoods", data3)
            .then((res) => {
              this.getList();
              this.$message.success("恢复成功");
            });
          this.confirmdialog = false;
        default:
          break;
      }
    },
    // 下架商品
    offGoods(goods_id) {
      // 失去焦点函数
      commonUtil.lostFocus(event);
      if (this.multipleSelection.length == 0 && !goods_id) {
        this.$message.error("请选择商品");
        return;
      }
      this.newmassage = "确定下架吗?";
      this.comkey = "takeoff";
      this.dia_goods_id = goods_id;
      this.confirmdialog = true;
      // this.$confirm("确定下架吗？", "提示消息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // }).then(() => {
      //   let arr = this.multipleSelection.map((ele) => {
      //     return ele.goods_id;
      //   });
      //   let data = {
      //     goods_id: goods_id || arr.toString(),
      //   };
      //   this.axios.post("/store/Shopgoods/offGoods", data).then((res) => {
      //     this.getList();
      //     this.$message.success("下架成功");
      //   });
      // });
    },
    // 复制商品
    copyGoods(goods_id) {
      this.$router.push({
        path: "/goods/AddGood",
        query: {
          goods_id,
        },
      });
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    // 上架商品
    onGoods(goods_id) {
      if (this.multipleSelection.length == 0 && !goods_id) {
        this.$message.error("请选择商品");
        return;
      }
      this.newmassage = "确定上架吗?";
      this.comkey = "puton";
      this.dia_goods_id = goods_id;
      this.confirmdialog = true;
      // this.$confirm("确定上架吗？", "提示消息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // }).then(() => {
      //   let arr = this.multipleSelection.map((ele) => {
      //     return ele.goods_id;
      //   });
      //   let data = {
      //     goods_id: goods_id || arr.toString(),
      //   };
      //   this.axios.post("/store/Shopgoods/onGoods", data).then((res) => {
      //     this.getList();
      //     this.$message.success("上架成功");
      //   });
      // });
    },
    //删除商品
    deleteGoodsSort(goods_id) {
      if (this.multipleSelection.length == 0 && !goods_id) {
        this.$message.error("请选择商品");
        return;
      }
      this.newmassage = "确定删除吗?";
      this.comkey = "delete";
      this.dia_goods_id = goods_id;
      this.confirmdialog = true;
      // this.$confirm("确定删除吗？", "提示消息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // }).then(() => {
      //   let arr = this.multipleSelection.map((ele) => {
      //     return ele.goods_id;
      //   });
      //   let data = {
      //     goods_id: goods_id || arr.toString(),
      //   };
      //   this.axios
      //     .post("/store/Shopgoods/deleteGoodsSort", data)
      //     .then((res) => {
      //       this.getList();
      //       this.$message.success("删除成功");
      //     });
      // });
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    //恢复商品
    recoveryDeleteGoods(goods_id) {
      if (this.multipleSelection.length == 0 && !goods_id) {
        this.$message.error("请选择商品");
        return;
      }
      this.newmassage = "确定恢复吗?";
      this.comkey = "recover";
      this.dia_goods_id = goods_id;
      this.confirmdialog = true;
      // this.$confirm("确定恢复吗？", "提示消息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // }).then(() => {
      //   let arr = this.multipleSelection.map((ele) => {
      //     return ele.goods_id;
      //   });
      //   let data = {
      //     goods_id: goods_id || arr.toString(),
      //   };
      //   this.axios
      //     .post("/store/Shopgoods/recoveryDeleteGoods", data)
      //     .then((res) => {
      //       this.getList();
      //       this.$message.success("恢复成功");
      //     });
      // });
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    //查看修改库存
    showStock(item) {
      if (item.open_spec == 0) {
        this.info = JSON.parse(JSON.stringify(item));
        this.stockShow = true;
      } else if (item.open_spec == 1) {
        //开启了规格
        let data = {
          goods_id: item.goods_id,
        };
        this.axios
          .post("/store/Shopgoods/getGoodsSkuStock", data)
          .then((res) => {
            let list = res.data.list;
            this.info = JSON.parse(JSON.stringify(item));
            this.info.list = list;
            this.stockShow = true;
          });
      }
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    // 仅查看库存
    checkStock(item) {
      if (item.open_spec == 0) {
        this.info = JSON.parse(JSON.stringify(item));
        this.stockShowCheck = true;
      } else if (item.open_spec == 1) {
        //开启了规格
        let data = {
          goods_id: item.goods_id,
        };
        this.axios
          .post("/store/Shopgoods/getGoodsSkuStock", data)
          .then((res) => {
            let list = res.data.list;
            this.info = JSON.parse(JSON.stringify(item));
            this.info.list = list;
            this.stockShowCheck = true;
          });
      }
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    //确认修改库存
    stockConfirm() {
      let check = false;
      this.$refs.stockForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      if (this.info.open_spec == 0) {
        let data = {
          goods_id: this.info.goods_id,
          sku_list: [
            { goods_id: this.info.goods_id, stock: this.info.stock, sku_id: 0 },
          ],
        };
        console.log("data", data);

        this.axios
          .post("/store/Shopgoods/editGoodsSkuStock", data)
          .then((res) => {
            this.$message.success("修改成功");
            this.info = {};
            this.stockShow = false;
            this.getList();
          });
      } else if (this.info.open_spec == 1) {
        let data = {
          goods_id: this.info.goods_id,
          sku_list: this.info.list,
        };
        console.log("data", data);
        this.axios
          .post("/store/Shopgoods/editGoodsSkuStock", data)
          .then((res) => {
            this.$message.success("修改成功");
            this.info = {};
            this.stockShow = false;
            this.getList();
          });
      }
      // 失去焦点函数
      commonUtil.lostFocus(event);
    },
    sortChange(e) {
      let prop = e.prop;
      let order = e.order;
      if (prop == "sort" && order == "descending") {
        this.order_sort = 2;
      } else if (prop == "sort" && order == "ascending") {
        this.order_sort = 1;
      } else if (prop == "create_time" && order == "descending") {
        this.order_sort = 4;
      } else if (prop == "create_time" && order == "ascending") {
        this.order_sort = 3;
      } else if (prop == "real_sales" && order == "descending") {
        this.order_sort = 8;
      } else if (prop == "real_sales" && order == "ascending") {
        this.order_sort = 7;
      } else if (prop == "stock" && order == "descending") {
        this.order_sort = 6;
      } else if (prop == "stock" && order == "ascending") {
        this.order_sort = 5;
      }
      console.log(prop, order);
      this.getList();
    },
    toEditGoods(goods_id) {
      this.$router.push({
        path: "/goods/editgood",
        query: {
          goods_id,
        },
      });
    },
    checkGoods(goods_id) {
      this.$router.push({
        path: "/goods/checkGood",
        query: {
          goods_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.imgbox {
  width: 76px;
  height: 76px;
}
.goodsImg {
  width: 76px;
  height: 76px;
  padding-right: 5px;
}
</style>
